import { combineReducers, createStore } from 'redux';

import { introReducer } from './reducerIntro';
import { loggedInReducer } from './reducerLoggedIn';
import { addressReducer } from './reducerAddress';

const rootReducer = combineReducers({
  intro: introReducer,
  loggedIn: loggedInReducer,
  address: addressReducer,
});

export default createStore(rootReducer);
