/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import {
  Col, Container, Row,
  ListGroup, Spinner, Button,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';

import SearchBox from './Components/SearchBox';

export const RESEARCH_COFO_PDF_LIST = gql`
  query Query($formData: ResearchSBLInput!) {
    result: ResearchCofOPDFList(formData: $formData) {
      status
      content
      type
    }
  }
`;

export const RESEARCH_TAX_SEARCH_SUMMARY = gql`
  query Query($formData: ResearchSBLInput!) {
    result: ResearchTaxSearchSummary(formData: $formData) {
      status
      content
      type
    }
  }
`;

const Property = () => {
  const address = useSelector((state) => state.address);
  const [cofoData, setCofoData] = useState([]);

  const {
    loading,
    // eslint-disable-next-line no-unused-vars
    error,
    data,
    stopPolling,
  } = useQuery(RESEARCH_COFO_PDF_LIST, {
    variables: {
      formData: {
        county: address.county,
        block: address.block,
        lot: address.lot,
        easement: address.easement,
        raw: true,
      },
    },
    fetchPolicy: 'no-cache',
    pollInterval: 5000,
  });

  // eslint-disable-next-line no-unused-vars
  const { loading: loadingTax, error: errorTax, data: dataTax } = useQuery(RESEARCH_TAX_SEARCH_SUMMARY, {
    variables: {
      formData: {
        county: address.county,
        block: address.block,
        lot: address.lot,
        easement: address.easement,
      },
    },
  });

  useEffect(() => {
    if (data) {
      if (data.result.status === 'READY') {
        stopPolling();
        const parsedData = JSON.parse(data.result.content);
        const pdfs = parsedData.pdfLinks.map((dobbin) => dobbin.pdfs.map((pdf, i) => ({
          dobbin,
          pdfName: pdf,
          pdfLink: dobbin.urls[i],
        }))).flat();
        setCofoData(pdfs);
      }
    }
  }, [data]);

  return (
    <div className="property">
      <div className="top-bg-wrapper">
        <Container>
          <Row>
            <Col>
              <div>
                <Link to="/" className="back">
                  <i className="far fa-arrow-alt-circle-left" />
                  {' '}
                  Back
                </Link>
                <SearchBox initAddress={address.fullAddress} />
              </div>
              <div className="copy">
                <p className="h1-tag">{`Block: ${address.block} • Lot: ${address.lot}`}</p>
                <h1>{`${address.fullAddress}`}</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col>
            <div className="co-info">
              <h2>Certificate of Occupancy</h2>
              {(loading || data?.result?.status === 'PROCESSING') ? (
                <div className="loading">
                  <div>Seaching NYC Database (this can take a while)</div>
                  <Spinner animation="border" />
                </div>
              ) : (
                <>
                  <ListGroup>
                    {(cofoData.length > 0) ? cofoData.map((dataRow, i) => (
                      <ListGroup.Item
                      // eslint-disable-next-line react/no-array-index-key
                        key={i}
                      >
                        <a href={dataRow.pdfLink} target="_blank" rel="noreferrer">{dataRow.pdfName}</a>
                      </ListGroup.Item>
                    )) : (
                      <ListGroup.Item>
                        No certificate is found.
                        For CO-OPs please enter address without the apartment number.
                        NYC only has digital copy of Certificate of Occupancy going back to 1986.
                        For a full examination of the property, order a full title report below.
                      </ListGroup.Item>
                    )}
                  </ListGroup>
                </>
              )}
            </div>
            <div className="tax-info">
              <h2>Tax Information</h2>
              {(loadingTax) ? (
                <div className="loading">
                  <div>Searching...</div>
                  <Spinner animation="border" />
                </div>
              ) : (
                <>
                  <ListGroup>
                    <ListGroup.Item>
                      <div
                        className="tax-search-summary"
                        dangerouslySetInnerHTML={{
                          __html: dataTax?.result?.content,
                        }}
                      />
                    </ListGroup.Item>
                  </ListGroup>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <div className="order-title-report">
        <Container>
          <Row>
            <Col>
              <div className="action">
                <Button>
                  Coming Soon
                  <br />
                  Order Title Report
                </Button>
              </div>
              <div>
                <p>
                  Title reports will show you crucial information about the property,
                  current ownership, and possible issues discovered related to
                  ownership. Deduct the cost of the report from our trusted title
                  insurance providers.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Property;
