/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import {
  Container, Row, Col, Modal,
} from 'react-bootstrap';
import SearchBox from '../Components/SearchBox';

const Home = () => {
  const [show, setShow] = useState(false);
  return (
    <div className="home">
      <Container>
        <Row>
          <Col>
            <h1><img src="/img/logo_dropshadow.png" alt="Real Estate Doctor" /></h1>
            <SearchBox />
            <p>
              Look up
              <button type="button" className="as-text" onClick={() => setShow(true)}>Tax History</button>
              and
              <br />
              <button type="button" className="as-text" onClick={() => setShow(true)}>Certificate of Occupancy</button>
            </p>
          </Col>
        </Row>
      </Container>
      <Modal
        show={show}
        size="lg"
        onHide={() => setShow(false)}
        className="modal-intro-title-report"
      >
        <Modal.Header closeButton>
          <h3>Intro to Title Report</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="full-width-video">
            <iframe
              title="Intro to Title Report"
              src="https://player.vimeo.com/video/591800895?h=f5fce335f0"
              width="640"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
          </div>
          <h4>Certificate of Occupancy</h4>
          <p>
            The Certificate of Occupancy is document that tells you whether
            existing sturcture on the property is safe. It will also shows you the
            zoning of the property: single family, multifamily, retail, commercial
            or mixed use.
          </p>
          <h4>Tax History</h4>
          <p>
            The tax history shows whether there any overdue
            payments on the property. It also shows you the property’s
            value at the time of assessment. Unpaid taxes on the Property
            will become your responsibilit once you close on the house.
          </p>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Home;
