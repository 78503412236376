import { faSearch } from '@fortawesome/free-solid-svg-icons';
import SmartyStreetsSDK from 'smartystreets-javascript-sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SearchSuggestions from './SearchSuggestions';
import { SMARTYST_API } from '../Constants';
import { STREET_SUFFIX } from '../StreetSuffix';
import { setParsedAddress } from '../Redux/reducerAddress';

const parser = require('parse-address');

const directional = {
  N: 'north',
  NE: 'northeast',
  E: 'east',
  SE: 'southeast',
  S: 'south',
  SW: 'southwest',
  W: 'west',
  NW: 'northwest',
};

const getFullStreetSuffix = (term) => {
  const found = STREET_SUFFIX.find((e) => e.Short === term.toUpperCase());
  return found.Full || term.toUpperCase();
};

const SearchBox = (props) => {
  const {
    initAddress = '',
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const [address, setAddress] = useState(initAddress);

  const SmartyStreetsCore = SmartyStreetsSDK.core;
  const { Lookup } = SmartyStreetsSDK.usStreet;
  const credentials = new SmartyStreetsCore.SharedCredentials(SMARTYST_API);
  const clientBuilder = new SmartyStreetsCore.ClientBuilder(credentials);
  const client = clientBuilder.buildUsStreetApiClient();

  const onInput = ({ target: { value } }) => setAddress(value);

  const getSmartyAddress = async (suggestion) => {
    const lookup = new Lookup();
    lookup.street = suggestion.streetLine;
    lookup.secondary = suggestion.secondary;
    lookup.city = suggestion.city;
    lookup.state = suggestion.state;
    lookup.zipcode = suggestion.zipcode;

    try {
      const { lookups } = await client.send(lookup);
      return lookups[0].result[0];
    } catch (e) {
      console.error(e);
      return {};
    }
  };

  const getParsedAddress = ({ components, metadata }) => {
    // parse houseNo from SearchBox address
    const { number } = parser.parseLocation(`${address} `);
    const county = metadata.countyName;
    const houseNo = components.primaryNumber;
    const houseNoWithHypen = (number.replace('-', '') === houseNo) ? number : houseNo;
    const aptNo = components.secondaryNumber;
    let street = components.streetName;
    let normalizedStreet = street.replace(/(\d+)(st|nd|rd|th)/g, '$1').toUpperCase();
    if (components.streetPredirection) {
      street = `${components.streetPredirection} ${street}`;
      normalizedStreet = `${directional[components.streetPredirection].toUpperCase()} ${normalizedStreet}`;
    }
    if (components.streetSuffix) {
      street = `${street} ${components.streetSuffix}`;
      normalizedStreet = `${normalizedStreet} ${getFullStreetSuffix(components.streetSuffix)}`;
    }
    if (components.streetPostdirection) {
      street = `${street} ${components.streetPostdirection}`;
      normalizedStreet = `${directional[components.streetPostdirection]} ${normalizedStreet}`;
    }
    let matchAddress = `${houseNoWithHypen} ${street}`;
    if (components.secondaryDesignator) matchAddress = `${matchAddress}, ${components.secondaryDesignator} ${components.secondaryNumber}`;
    matchAddress = `${matchAddress}, ${county}`;

    return {
      county,
      houseNo,
      houseNoWithHypen,
      street,
      aptNo,
      matchAddress,
      normalizedStreet,
    };
  };

  const selectSuggestion = async (suggestion) => {
    const smartyAddress = await getSmartyAddress(suggestion);
    const parsedAddress = getParsedAddress(smartyAddress);
    setAddress(parsedAddress.matchAddress);
    dispatch(setParsedAddress({
      county: parsedAddress.county,
      houseNo: parsedAddress.houseNoWithHypen,
      aptNo: parsedAddress.aptNo,
      street: parsedAddress.normalizedStreet,
      fullAddress: parsedAddress.matchAddress,
    }));
    history.push({
      pathname: '/search',
    });
  };

  return (
    <div className="search-form">
      <InputGroup>
        <FormControl
          id="inlineFormInputGroup"
          placeholder="Property Address"
          onChange={onInput}
          value={address}
        />
        <Button>
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </InputGroup>
      <SearchSuggestions
        address={address}
        selectSuggestion={selectSuggestion}
      />
    </div>
  );
};

SearchBox.propTypes = {
  initAddress: PropTypes.string,
};

SearchBox.defaultProps = {
  initAddress: '',
};

export default SearchBox;
