import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SmartyStreetsSDK from 'smartystreets-javascript-sdk';
import { ListGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useDebounce from '../use-debounce';
import { SMARTYST_API } from '../Constants';

const SearchSuggestions = (props) => {
  const { address, selectSuggestion } = props;

  const [suggestions, setSuggestions] = useState([]);

  const stateAddress = useSelector((state) => state.address);

  const SmartyStreetsCore = SmartyStreetsSDK.core;
  const { Lookup } = SmartyStreetsSDK.usAutocompletePro;
  const credentials = new SmartyStreetsCore.SharedCredentials(SMARTYST_API);
  const clientBuilder = new SmartyStreetsCore.ClientBuilder(credentials).withLicenses(['us-autocomplete-pro-cloud']);
  const client = clientBuilder.buildUsAutocompleteProClient();

  const debouncedSearchTerm = useDebounce(address, 300);

  useEffect(
    async () => {
      if (debouncedSearchTerm && address !== stateAddress.fullAddress) {
        const lookup = new Lookup(debouncedSearchTerm);
        lookup.includeOnlyStates = ['NY'];
        try {
          const { result } = await client.send(lookup);
          setSuggestions(result);
        } catch (e) {
          console.error(e);
          setSuggestions([]);
        }
      } else {
        setSuggestions([]);
      }
    },
    [debouncedSearchTerm, stateAddress],
  );

  const suggestionText = (suggestion) => {
    let text = suggestion.streetLine;
    if (suggestion.entries > 1) {
      text = `${text}, ${suggestion.secondary} (${suggestion.entries})`;
    } else if (suggestion.entries === 1) {
      text = `${text}, ${suggestion.secondary}`;
    }
    text = `${text}, ${suggestion.city}, ${suggestion.state}, ${suggestion.zipcode}`;
    return text;
  };

  return (
    <ListGroup className="autocomplete--suggestions">
      {suggestions.map((suggestion) => (
        <ListGroup.Item
          key={`${suggestion.streetLine}-${suggestion.secondary}-${suggestion.zipcode}`}
          onClick={() => selectSuggestion(suggestion)}
        >
          {suggestionText(suggestion)}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

SearchSuggestions.propTypes = {
  address: PropTypes.string.isRequired,
  selectSuggestion: PropTypes.func.isRequired,
};

export default SearchSuggestions;
