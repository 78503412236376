import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';
import { useDispatch } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';

import { setIntro } from '../Redux/reducerIntro';

const Intro = () => {
  const { step } = useParams();
  const dispatch = useDispatch();
  if (step === '2') {
    dispatch(setIntro(true));
  }
  return (
    <div className="intro">
      <Container>
        <Row>
          <Col>
            {step !== '2' ? (
              <div>
                <h1><img src="/img/logo_white.png" alt="Real Estate Doctor" /></h1>
                <p>Look up tax history and certificate of occupany</p>
                <Link to="/intro/2" className="as-button">
                  Next
                  {' '}
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />
                </Link>
              </div>
            ) : (
              <div>
                <h1>All you need:</h1>
                <ul>
                  <li>
                    <i className="far fa-check-square" />
                    {' '}
                    Address
                  </li>
                  <li>
                    <i className="far fa-check-square" />
                    {' '}
                    Borough
                  </li>
                </ul>
                <Link to="/" className="as-button">
                  You&rsquo;re all set
                  {' '}
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />
                </Link>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Intro;
