import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import {
  ApolloClient, InMemoryCache, HttpLink, ApolloLink,
} from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';

import reportWebVitals from './reportWebVitals';

import App from './App';
import store from './Redux/store';
import { GRAPHQL_SERVER_URL } from './Constants';

const client = new ApolloClient({
  link: new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        authorization: `Bearer ${Date.now()}`, // Your Auth token extraction logic
      },
    });
    return forward(operation);
  }).concat(
    new HttpLink({
      uri: GRAPHQL_SERVER_URL, // Server URL
    }),
  ),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
