export const SET_LOGGEDIN = 'SET_LOGGEDIN';

export const setLoggedIn = (input) => ({
  type: SET_LOGGEDIN,
  payload: input,
});
const initLoggedIn = false;

export const loggedInReducer = (state = initLoggedIn, action) => {
  switch (action.type) {
    case SET_LOGGEDIN:
      return action.payload;
    default:
      return state;
  }
};
