import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  Col, Container, ListGroup, Row, Spinner,
} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import gql from 'graphql-tag';
import { useDispatch, useSelector } from 'react-redux';
import { setSBL } from './Redux/reducerAddress';
import SearchBox from './Components/SearchBox';

export const RESEARCH_LOOKUP_SBL = gql`
  query Query($formData: ResearchAddressInput!) {
    results: ResearchLookupSBL(formData: $formData) {
      parid
      county
      street
      boro
      block
      lot
      easement
      houseNo
      aptno
      owner
      zip
    }
  }
`;

const Search = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [getSBLS, { loading, error, data }] = useLazyQuery(RESEARCH_LOOKUP_SBL);
  const address = useSelector((state) => state.address);

  useEffect(() => {
    getSBLS({
      variables: {
        formData: {
          county: address.county,
          houseNo: address.houseNo,
          street: address.street,
          aptNo: address.aptNo,
        },
      },
    });
  }, [address]);

  const handleSearchClick = (i) => {
    dispatch(setSBL({
      boro: data.results[i].boro,
      block: data.results[i].block,
      lot: data.results[i].lot,
      easement: data.results[i].easement,
    }));

    history.push({
      pathname: '/property',
    });
  };

  const sblText = (sbl) => {
    let text = `${sbl.houseNo} ${sbl.street}`;
    if (sbl.aptno) text = `${text} (${sbl.aptno})`;
    text = `${text}, ${sbl.county}, Owner: ${sbl.owner}`;
    return text;
  };

  return (
    <div className="search">
      <div className="top-bg-wrapper">
        <Container>
          <Row>
            <Col>
              <div>
                <Link to="/" className="back">
                  <i className="far fa-arrow-alt-circle-left" />
                  {' '}
                  Back
                </Link>
              </div>
              <div className="copy">
                <p className="h1-tag">Quick Search</p>
                <h1>Records at this Address</h1>
                <SearchBox initAddress={address.fullAddress} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col>
            { (loading) ? (
              <div className="loading">
                <div>Searching...</div>
                <Spinner animation="border" />
              </div>
            ) : (
              <ListGroup className="list-with-action">
                {data !== undefined && data?.results.length > 0 ? (
                  <>
                    {data.results.map((result, i) => (
                      <ListGroup.Item
                        key={result.parid}
                        onClick={() => handleSearchClick(i)}
                      >
                        <div>{sblText(result)}</div>
                        <div><FontAwesomeIcon icon={faAngleRight} /></div>
                      </ListGroup.Item>
                    ))}
                  </>
                ) : (
                  <div>
                    No property entries can be found for this address.
                    If your address includes a dash, please be sure to include it.
                  </div>
                )}
              </ListGroup>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Search;
