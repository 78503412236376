import React from 'react';
import { Route, Switch } from 'react-router';

import './scss/App.scss';

import Home from './Home/Home';
import Intro from './Home/Intro';
import Search from './Search';
import Property from './Property';
import AddressAutoComplete from './UX/AddressAutoComplete';

const App = () => (
  <div className="app">
    <Switch>
      <Route path="/intro/:step"><Intro /></Route>
      <Route path="/intro"><Intro /></Route>
      <Route path="/search"><Search /></Route>
      <Route path="/property"><Property /></Route>
      <Route path="/account"><Home /></Route>
      <Route path="/account/login"><Home /></Route>
      <Route path="/account/logout"><Home /></Route>
      <Route path="/past-orders"><Home /></Route>
      <Route path="/ux/autocomplete"><AddressAutoComplete /></Route>
      <Route path="/"><Home /></Route>
    </Switch>
  </div>
);

export default App;
