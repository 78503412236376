import React, { useEffect, useState } from 'react';
import SmartyStreetsSDK from 'smartystreets-javascript-sdk';
import { SMARTYST_API } from '../Constants';

const AddressAutoComplete = () => {
  const [addressInput, setAddressInput] = useState('');
  const handleAddressInputChange = (value) => {
    setAddressInput(value);
  };
  const SmartyStreetsCore = SmartyStreetsSDK.core;
  const { Lookup } = SmartyStreetsSDK.usAutocompletePro;
  const credentials = new SmartyStreetsCore.SharedCredentials(SMARTYST_API);
  const clientBuilder = new SmartyStreetsCore.ClientBuilder(credentials).withLicenses(['us-autocomplete-pro-cloud']);

  const client = clientBuilder.buildUsAutocompleteProClient();

  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    if (addressInput.length > 2) {
      const lookup = new Lookup(addressInput);
      lookup.stateFilter = ['NY'];
      client.send(lookup)
        .then((response) => {
          setSuggestions(response.result);
        })
        // eslint-disable-next-line no-console
        .catch(console.log);
    }
  }, [addressInput]);
  return (
    <div style={{ marginTop: '50px', padding: '2em' }}>
      <input
        value={addressInput}
        onChange={(e) => handleAddressInputChange(e.target.value)}
      />
      <div>
        {suggestions.map((row, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i}>
            {`${row.streetLine}, `}
            {`${row.secondary} (${row.entries}) `}
            {`${row.city}, ${row.state} ${row.zipcode}`}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddressAutoComplete;
