/* eslint-disable import/prefer-default-export */
export const STREET_SUFFIX = [
  {
    Full: 'ALLEY',
    Short: 'ALLEE',
  },
  {
    Full: 'ALLEY',
    Short: 'ALLEY',
  },
  {
    Full: 'ALLEY',
    Short: 'ALLY',
  },
  {
    Full: 'ALLEY',
    Short: 'ALY',
  },
  {
    Full: 'ALLEY',
    Short: 'ALY',
  },
  {
    Full: 'ANEX',
    Short: 'ANEX',
  },
  {
    Full: 'ANEX',
    Short: 'ANNEX',
  },
  {
    Full: 'ANEX',
    Short: 'ANNX',
  },
  {
    Full: 'ANEX',
    Short: 'ANX',
  },
  {
    Full: 'ANEX',
    Short: 'ANX',
  },
  {
    Full: 'ARCADE',
    Short: 'ARC',
  },
  {
    Full: 'ARCADE',
    Short: 'ARCADE',
  },
  {
    Full: 'ARCADE',
    Short: 'ARC',
  },
  {
    Full: 'AVENUE',
    Short: 'AV',
  },
  {
    Full: 'AVENUE',
    Short: 'AVE',
  },
  {
    Full: 'AVENUE',
    Short: 'AVEN',
  },
  {
    Full: 'AVENUE',
    Short: 'AVENU',
  },
  {
    Full: 'AVENUE',
    Short: 'AVENUE',
  },
  {
    Full: 'AVENUE',
    Short: 'AVN',
  },
  {
    Full: 'AVENUE',
    Short: 'AVNUE',
  },
  {
    Full: 'AVENUE',
    Short: 'AVE',
  },
  {
    Full: 'BAYOU',
    Short: 'BAYOO',
  },
  {
    Full: 'BAYOU',
    Short: 'BAYOU',
  },
  {
    Full: 'BAYOU',
    Short: 'BYU',
  },
  {
    Full: 'BEACH',
    Short: 'BCH',
  },
  {
    Full: 'BEACH',
    Short: 'BEACH',
  },
  {
    Full: 'BEACH',
    Short: 'BCH',
  },
  {
    Full: 'BEND',
    Short: 'BEND',
  },
  {
    Full: 'BEND',
    Short: 'BND',
  },
  {
    Full: 'BEND',
    Short: 'BND',
  },
  {
    Full: 'BLUFF',
    Short: 'BLF',
  },
  {
    Full: 'BLUFF',
    Short: 'BLUF',
  },
  {
    Full: 'BLUFF',
    Short: 'BLUFF',
  },
  {
    Full: 'BLUFF',
    Short: 'BLF',
  },
  {
    Full: 'BLUFFS',
    Short: 'BLUFFS',
  },
  {
    Full: 'BLUFFS',
    Short: 'BLFS',
  },
  {
    Full: 'BOTTOM',
    Short: 'BOT',
  },
  {
    Full: 'BOTTOM',
    Short: 'BTM',
  },
  {
    Full: 'BOTTOM',
    Short: 'BOTTM',
  },
  {
    Full: 'BOTTOM',
    Short: 'BOTTOM',
  },
  {
    Full: 'BOTTOM',
    Short: 'BTM',
  },
  {
    Full: 'BOULEVARD',
    Short: 'BLVD',
  },
  {
    Full: 'BOULEVARD',
    Short: 'BOUL',
  },
  {
    Full: 'BOULEVARD',
    Short: 'BOULEVARD',
  },
  {
    Full: 'BOULEVARD',
    Short: 'BOULV',
  },
  {
    Full: 'BOULEVARD',
    Short: 'BLVD',
  },
  {
    Full: 'BRANCH',
    Short: 'BR',
  },
  {
    Full: 'BRANCH',
    Short: 'BRNCH',
  },
  {
    Full: 'BRANCH',
    Short: 'BRANCH',
  },
  {
    Full: 'BRANCH',
    Short: 'BR',
  },
  {
    Full: 'BRIDGE',
    Short: 'BRDGE',
  },
  {
    Full: 'BRIDGE',
    Short: 'BRG',
  },
  {
    Full: 'BRIDGE',
    Short: 'BRIDGE',
  },
  {
    Full: 'BRIDGE',
    Short: 'BRG',
  },
  {
    Full: 'BROOK',
    Short: 'BRK',
  },
  {
    Full: 'BROOK',
    Short: 'BROOK',
  },
  {
    Full: 'BROOK',
    Short: 'BRK',
  },
  {
    Full: 'BROOKS',
    Short: 'BROOKS',
  },
  {
    Full: 'BROOKS',
    Short: 'BRKS',
  },
  {
    Full: 'BURG',
    Short: 'BURG',
  },
  {
    Full: 'BURG',
    Short: 'BG',
  },
  {
    Full: 'BURGS',
    Short: 'BURGS',
  },
  {
    Full: 'BURGS',
    Short: 'BGS',
  },
  {
    Full: 'BYPASS',
    Short: 'BYP',
  },
  {
    Full: 'BYPASS',
    Short: 'BYPA',
  },
  {
    Full: 'BYPASS',
    Short: 'BYPAS',
  },
  {
    Full: 'BYPASS',
    Short: 'BYPASS',
  },
  {
    Full: 'BYPASS',
    Short: 'BYPS',
  },
  {
    Full: 'BYPASS',
    Short: 'BYP',
  },
  {
    Full: 'CAMP',
    Short: 'CAMP',
  },
  {
    Full: 'CAMP',
    Short: 'CP',
  },
  {
    Full: 'CAMP',
    Short: 'CMP',
  },
  {
    Full: 'CAMP',
    Short: 'CP',
  },
  {
    Full: 'CANYON',
    Short: 'CANYN',
  },
  {
    Full: 'CANYON',
    Short: 'CANYON',
  },
  {
    Full: 'CANYON',
    Short: 'CNYN',
  },
  {
    Full: 'CANYON',
    Short: 'CYN',
  },
  {
    Full: 'CAPE',
    Short: 'CAPE',
  },
  {
    Full: 'CAPE',
    Short: 'CPE',
  },
  {
    Full: 'CAPE',
    Short: 'CPE',
  },
  {
    Full: 'CAUSEWAY',
    Short: 'CAUSEWAY',
  },
  {
    Full: 'CAUSEWAY',
    Short: 'CAUSWA',
  },
  {
    Full: 'CAUSEWAY',
    Short: 'CSWY',
  },
  {
    Full: 'CAUSEWAY',
    Short: 'CSWY',
  },
  {
    Full: 'CENTER',
    Short: 'CEN',
  },
  {
    Full: 'CENTER',
    Short: 'CENT',
  },
  {
    Full: 'CENTER',
    Short: 'CENTER',
  },
  {
    Full: 'CENTER',
    Short: 'CENTR',
  },
  {
    Full: 'CENTER',
    Short: 'CENTRE',
  },
  {
    Full: 'CENTER',
    Short: 'CNTER',
  },
  {
    Full: 'CENTER',
    Short: 'CNTR',
  },
  {
    Full: 'CENTER',
    Short: 'CTR',
  },
  {
    Full: 'CENTER',
    Short: 'CTR',
  },
  {
    Full: 'CENTERS',
    Short: 'CENTERS',
  },
  {
    Full: 'CENTERS',
    Short: 'CTRS',
  },
  {
    Full: 'CIRCLE',
    Short: 'CIR',
  },
  {
    Full: 'CIRCLE',
    Short: 'CIRC',
  },
  {
    Full: 'CIRCLE',
    Short: 'CIRCL',
  },
  {
    Full: 'CIRCLE',
    Short: 'CIRCLE',
  },
  {
    Full: 'CIRCLE',
    Short: 'CRCL',
  },
  {
    Full: 'CIRCLE',
    Short: 'CRCLE',
  },
  {
    Full: 'CIRCLE',
    Short: 'CIR',
  },
  {
    Full: 'CIRCLES',
    Short: 'CIRCLES',
  },
  {
    Full: 'CIRCLES',
    Short: 'CIRS',
  },
  {
    Full: 'CLIFF',
    Short: 'CLF',
  },
  {
    Full: 'CLIFF',
    Short: 'CLIFF',
  },
  {
    Full: 'CLIFF',
    Short: 'CLF',
  },
  {
    Full: 'CLIFFS',
    Short: 'CLFS',
  },
  {
    Full: 'CLIFFS',
    Short: 'CLIFFS',
  },
  {
    Full: 'CLIFFS',
    Short: 'CLFS',
  },
  {
    Full: 'CLUB',
    Short: 'CLB',
  },
  {
    Full: 'CLUB',
    Short: 'CLUB',
  },
  {
    Full: 'CLUB',
    Short: 'CLB',
  },
  {
    Full: 'COMMON',
    Short: 'COMMON',
  },
  {
    Full: 'COMMON',
    Short: 'CMN',
  },
  {
    Full: 'COMMONS',
    Short: 'COMMONS',
  },
  {
    Full: 'COMMONS',
    Short: 'CMNS',
  },
  {
    Full: 'CORNER',
    Short: 'COR',
  },
  {
    Full: 'CORNER',
    Short: 'CORNER',
  },
  {
    Full: 'CORNER',
    Short: 'COR',
  },
  {
    Full: 'CORNERS',
    Short: 'CORNERS',
  },
  {
    Full: 'CORNERS',
    Short: 'CORS',
  },
  {
    Full: 'CORNERS',
    Short: 'CORS',
  },
  {
    Full: 'COURSE',
    Short: 'COURSE',
  },
  {
    Full: 'COURSE',
    Short: 'CRSE',
  },
  {
    Full: 'COURSE',
    Short: 'CRSE',
  },
  {
    Full: 'COURT',
    Short: 'COURT',
  },
  {
    Full: 'COURT',
    Short: 'CT',
  },
  {
    Full: 'COURT',
    Short: 'CT',
  },
  {
    Full: 'COURTS',
    Short: 'COURTS',
  },
  {
    Full: 'COURTS',
    Short: 'CTS',
  },
  {
    Full: 'COURTS',
    Short: 'CTS',
  },
  {
    Full: 'COVE',
    Short: 'COVE',
  },
  {
    Full: 'COVE',
    Short: 'CV',
  },
  {
    Full: 'COVE',
    Short: 'CV',
  },
  {
    Full: 'COVES',
    Short: 'COVES',
  },
  {
    Full: 'COVES',
    Short: 'CVS',
  },
  {
    Full: 'CREEK',
    Short: 'CREEK',
  },
  {
    Full: 'CREEK',
    Short: 'CRK',
  },
  {
    Full: 'CREEK',
    Short: 'CRK',
  },
  {
    Full: 'CRESCENT',
    Short: 'CRESCENT',
  },
  {
    Full: 'CRESCENT',
    Short: 'CRES',
  },
  {
    Full: 'CRESCENT',
    Short: 'CRSENT',
  },
  {
    Full: 'CRESCENT',
    Short: 'CRSNT',
  },
  {
    Full: 'CRESCENT',
    Short: 'CRES',
  },
  {
    Full: 'CREST',
    Short: 'CREST',
  },
  {
    Full: 'CREST',
    Short: 'CRST',
  },
  {
    Full: 'CROSSING',
    Short: 'CROSSING',
  },
  {
    Full: 'CROSSING',
    Short: 'CRSSNG',
  },
  {
    Full: 'CROSSING',
    Short: 'XING',
  },
  {
    Full: 'CROSSING',
    Short: 'XING',
  },
  {
    Full: 'CROSSROAD',
    Short: 'CROSSROAD',
  },
  {
    Full: 'CROSSROAD',
    Short: 'XRD',
  },
  {
    Full: 'CROSSROADS',
    Short: 'CROSSROADS',
  },
  {
    Full: 'CROSSROADS',
    Short: 'XRDS',
  },
  {
    Full: 'CURVE',
    Short: 'CURVE',
  },
  {
    Full: 'CURVE',
    Short: 'CURV',
  },
  {
    Full: 'DALE',
    Short: 'DALE',
  },
  {
    Full: 'DALE',
    Short: 'DL',
  },
  {
    Full: 'DALE',
    Short: 'DL',
  },
  {
    Full: 'DAM',
    Short: 'DAM',
  },
  {
    Full: 'DAM',
    Short: 'DM',
  },
  {
    Full: 'DAM',
    Short: 'DM',
  },
  {
    Full: 'DIVIDE',
    Short: 'DIV',
  },
  {
    Full: 'DIVIDE',
    Short: 'DIVIDE',
  },
  {
    Full: 'DIVIDE',
    Short: 'DV',
  },
  {
    Full: 'DIVIDE',
    Short: 'DVD',
  },
  {
    Full: 'DIVIDE',
    Short: 'DV',
  },
  {
    Full: 'DRIVE',
    Short: 'DR',
  },
  {
    Full: 'DRIVE',
    Short: 'DRIV',
  },
  {
    Full: 'DRIVE',
    Short: 'DRIVE',
  },
  {
    Full: 'DRIVE',
    Short: 'DRV',
  },
  {
    Full: 'DRIVE',
    Short: 'DR',
  },
  {
    Full: 'DRIVES',
    Short: 'DRIVES',
  },
  {
    Full: 'DRIVES',
    Short: 'DRS',
  },
  {
    Full: 'ESTATE',
    Short: 'EST',
  },
  {
    Full: 'ESTATE',
    Short: 'ESTATE',
  },
  {
    Full: 'ESTATE',
    Short: 'EST',
  },
  {
    Full: 'ESTATES',
    Short: 'ESTATES',
  },
  {
    Full: 'ESTATES',
    Short: 'ESTS',
  },
  {
    Full: 'ESTATES',
    Short: 'ESTS',
  },
  {
    Full: 'EXPRESSWAY',
    Short: 'EXP',
  },
  {
    Full: 'EXPRESSWAY',
    Short: 'EXPR',
  },
  {
    Full: 'EXPRESSWAY',
    Short: 'EXPRESS',
  },
  {
    Full: 'EXPRESSWAY',
    Short: 'EXPRESSWAY',
  },
  {
    Full: 'EXPRESSWAY',
    Short: 'EXPW',
  },
  {
    Full: 'EXPRESSWAY',
    Short: 'EXPY',
  },
  {
    Full: 'EXPRESSWAY',
    Short: 'EXPY',
  },
  {
    Full: 'EXTENSION',
    Short: 'EXT',
  },
  {
    Full: 'EXTENSION',
    Short: 'EXTENSION',
  },
  {
    Full: 'EXTENSION',
    Short: 'EXTN',
  },
  {
    Full: 'EXTENSION',
    Short: 'EXTNSN',
  },
  {
    Full: 'EXTENSION',
    Short: 'EXT',
  },
  {
    Full: 'EXTENSIONS',
    Short: 'EXTS',
  },
  {
    Full: 'EXTENSIONS',
    Short: 'EXTS',
  },
  {
    Full: 'FALL',
    Short: 'FALL',
  },
  {
    Full: 'FALL',
    Short: 'FALL',
  },
  {
    Full: 'FALLS',
    Short: 'FALLS',
  },
  {
    Full: 'FALLS',
    Short: 'FLS',
  },
  {
    Full: 'FALLS',
    Short: 'FLS',
  },
  {
    Full: 'FERRY',
    Short: 'FERRY',
  },
  {
    Full: 'FERRY',
    Short: 'FRRY',
  },
  {
    Full: 'FERRY',
    Short: 'FRY',
  },
  {
    Full: 'FERRY',
    Short: 'FRY',
  },
  {
    Full: 'FIELD',
    Short: 'FIELD',
  },
  {
    Full: 'FIELD',
    Short: 'FLD',
  },
  {
    Full: 'FIELD',
    Short: 'FLD',
  },
  {
    Full: 'FIELDS',
    Short: 'FIELDS',
  },
  {
    Full: 'FIELDS',
    Short: 'FLDS',
  },
  {
    Full: 'FIELDS',
    Short: 'FLDS',
  },
  {
    Full: 'FLAT',
    Short: 'FLAT',
  },
  {
    Full: 'FLAT',
    Short: 'FLT',
  },
  {
    Full: 'FLAT',
    Short: 'FLT',
  },
  {
    Full: 'FLATS',
    Short: 'FLATS',
  },
  {
    Full: 'FLATS',
    Short: 'FLTS',
  },
  {
    Full: 'FLATS',
    Short: 'FLTS',
  },
  {
    Full: 'FORD',
    Short: 'FORD',
  },
  {
    Full: 'FORD',
    Short: 'FRD',
  },
  {
    Full: 'FORD',
    Short: 'FRD',
  },
  {
    Full: 'FORDS',
    Short: 'FORDS',
  },
  {
    Full: 'FORDS',
    Short: 'FRDS',
  },
  {
    Full: 'FOREST',
    Short: 'FOREST',
  },
  {
    Full: 'FOREST',
    Short: 'FORESTS',
  },
  {
    Full: 'FOREST',
    Short: 'FRST',
  },
  {
    Full: 'FOREST',
    Short: 'FRST',
  },
  {
    Full: 'FORGE',
    Short: 'FORG',
  },
  {
    Full: 'FORGE',
    Short: 'FORGE',
  },
  {
    Full: 'FORGE',
    Short: 'FRG',
  },
  {
    Full: 'FORGE',
    Short: 'FRG',
  },
  {
    Full: 'FORGES',
    Short: 'FORGES',
  },
  {
    Full: 'FORGES',
    Short: 'FRGS',
  },
  {
    Full: 'FORK',
    Short: 'FORK',
  },
  {
    Full: 'FORK',
    Short: 'FRK',
  },
  {
    Full: 'FORK',
    Short: 'FRK',
  },
  {
    Full: 'FORKS',
    Short: 'FORKS',
  },
  {
    Full: 'FORKS',
    Short: 'FRKS',
  },
  {
    Full: 'FORKS',
    Short: 'FRKS',
  },
  {
    Full: 'FORT',
    Short: 'FORT',
  },
  {
    Full: 'FORT',
    Short: 'FRT',
  },
  {
    Full: 'FORT',
    Short: 'FT',
  },
  {
    Full: 'FORT',
    Short: 'FT',
  },
  {
    Full: 'FREEWAY',
    Short: 'FREEWAY',
  },
  {
    Full: 'FREEWAY',
    Short: 'FREEWY',
  },
  {
    Full: 'FREEWAY',
    Short: 'FRWAY',
  },
  {
    Full: 'FREEWAY',
    Short: 'FRWY',
  },
  {
    Full: 'FREEWAY',
    Short: 'FWY',
  },
  {
    Full: 'FREEWAY',
    Short: 'FWY',
  },
  {
    Full: 'GARDEN',
    Short: 'GARDEN',
  },
  {
    Full: 'GARDEN',
    Short: 'GARDN',
  },
  {
    Full: 'GARDEN',
    Short: 'GRDEN',
  },
  {
    Full: 'GARDEN',
    Short: 'GRDN',
  },
  {
    Full: 'GARDEN',
    Short: 'GDN',
  },
  {
    Full: 'GARDENS',
    Short: 'GARDENS',
  },
  {
    Full: 'GARDENS',
    Short: 'GDNS',
  },
  {
    Full: 'GARDENS',
    Short: 'GRDNS',
  },
  {
    Full: 'GARDENS',
    Short: 'GDNS',
  },
  {
    Full: 'GATEWAY',
    Short: 'GATEWAY',
  },
  {
    Full: 'GATEWAY',
    Short: 'GATEWY',
  },
  {
    Full: 'GATEWAY',
    Short: 'GATWAY',
  },
  {
    Full: 'GATEWAY',
    Short: 'GTWAY',
  },
  {
    Full: 'GATEWAY',
    Short: 'GTWY',
  },
  {
    Full: 'GATEWAY',
    Short: 'GTWY',
  },
  {
    Full: 'GLEN',
    Short: 'GLEN',
  },
  {
    Full: 'GLEN',
    Short: 'GLN',
  },
  {
    Full: 'GLEN',
    Short: 'GLN',
  },
  {
    Full: 'GLENS',
    Short: 'GLENS',
  },
  {
    Full: 'GLENS',
    Short: 'GLNS',
  },
  {
    Full: 'GREEN',
    Short: 'GREEN',
  },
  {
    Full: 'GREEN',
    Short: 'GRN',
  },
  {
    Full: 'GREEN',
    Short: 'GRN',
  },
  {
    Full: 'GREENS',
    Short: 'GREENS',
  },
  {
    Full: 'GREENS',
    Short: 'GRNS',
  },
  {
    Full: 'GROVE',
    Short: 'GROV',
  },
  {
    Full: 'GROVE',
    Short: 'GROVE',
  },
  {
    Full: 'GROVE',
    Short: 'GRV',
  },
  {
    Full: 'GROVE',
    Short: 'GRV',
  },
  {
    Full: 'GROVES',
    Short: 'GROVES',
  },
  {
    Full: 'GROVES',
    Short: 'GRVS',
  },
  {
    Full: 'HARBOR',
    Short: 'HARB',
  },
  {
    Full: 'HARBOR',
    Short: 'HARBOR',
  },
  {
    Full: 'HARBOR',
    Short: 'HARBR',
  },
  {
    Full: 'HARBOR',
    Short: 'HBR',
  },
  {
    Full: 'HARBOR',
    Short: 'HRBOR',
  },
  {
    Full: 'HARBOR',
    Short: 'HBR',
  },
  {
    Full: 'HARBORS',
    Short: 'HARBORS',
  },
  {
    Full: 'HARBORS',
    Short: 'HBRS',
  },
  {
    Full: 'HAVEN',
    Short: 'HAVEN',
  },
  {
    Full: 'HAVEN',
    Short: 'HVN',
  },
  {
    Full: 'HAVEN',
    Short: 'HVN',
  },
  {
    Full: 'HEIGHTS',
    Short: 'HT',
  },
  {
    Full: 'HEIGHTS',
    Short: 'HTS',
  },
  {
    Full: 'HEIGHTS',
    Short: 'HTS',
  },
  {
    Full: 'HIGHWAY',
    Short: 'HIGHWAY',
  },
  {
    Full: 'HIGHWAY',
    Short: 'HIGHWY',
  },
  {
    Full: 'HIGHWAY',
    Short: 'HIWAY',
  },
  {
    Full: 'HIGHWAY',
    Short: 'HIWY',
  },
  {
    Full: 'HIGHWAY',
    Short: 'HWAY',
  },
  {
    Full: 'HIGHWAY',
    Short: 'HWY',
  },
  {
    Full: 'HIGHWAY',
    Short: 'HWY',
  },
  {
    Full: 'HILL',
    Short: 'HILL',
  },
  {
    Full: 'HILL',
    Short: 'HL',
  },
  {
    Full: 'HILL',
    Short: 'HL',
  },
  {
    Full: 'HILLS',
    Short: 'HILLS',
  },
  {
    Full: 'HILLS',
    Short: 'HLS',
  },
  {
    Full: 'HILLS',
    Short: 'HLS',
  },
  {
    Full: 'HOLLOW',
    Short: 'HLLW',
  },
  {
    Full: 'HOLLOW',
    Short: 'HOLLOW',
  },
  {
    Full: 'HOLLOW',
    Short: 'HOLLOWS',
  },
  {
    Full: 'HOLLOW',
    Short: 'HOLW',
  },
  {
    Full: 'HOLLOW',
    Short: 'HOLWS',
  },
  {
    Full: 'HOLLOW',
    Short: 'HOLW',
  },
  {
    Full: 'INLET',
    Short: 'INLT',
  },
  {
    Full: 'INLET',
    Short: 'INLT',
  },
  {
    Full: 'ISLAND',
    Short: 'IS',
  },
  {
    Full: 'ISLAND',
    Short: 'ISLAND',
  },
  {
    Full: 'ISLAND',
    Short: 'ISLND',
  },
  {
    Full: 'ISLAND',
    Short: 'IS',
  },
  {
    Full: 'ISLANDS',
    Short: 'ISLANDS',
  },
  {
    Full: 'ISLANDS',
    Short: 'ISLNDS',
  },
  {
    Full: 'ISLANDS',
    Short: 'ISS',
  },
  {
    Full: 'ISLANDS',
    Short: 'ISS',
  },
  {
    Full: 'ISLE',
    Short: 'ISLE',
  },
  {
    Full: 'ISLE',
    Short: 'ISLES',
  },
  {
    Full: 'ISLE',
    Short: 'ISLE',
  },
  {
    Full: 'JUNCTION',
    Short: 'JCT',
  },
  {
    Full: 'JUNCTION',
    Short: 'JCTION',
  },
  {
    Full: 'JUNCTION',
    Short: 'JCTN',
  },
  {
    Full: 'JUNCTION',
    Short: 'JUNCTION',
  },
  {
    Full: 'JUNCTION',
    Short: 'JUNCTN',
  },
  {
    Full: 'JUNCTION',
    Short: 'JUNCTON',
  },
  {
    Full: 'JUNCTION',
    Short: 'JCT',
  },
  {
    Full: 'JUNCTIONS',
    Short: 'JCTNS',
  },
  {
    Full: 'JUNCTIONS',
    Short: 'JCTS',
  },
  {
    Full: 'JUNCTIONS',
    Short: 'JUNCTIONS',
  },
  {
    Full: 'JUNCTIONS',
    Short: 'JCTS',
  },
  {
    Full: 'KEY',
    Short: 'KEY',
  },
  {
    Full: 'KEY',
    Short: 'KY',
  },
  {
    Full: 'KEY',
    Short: 'KY',
  },
  {
    Full: 'KEYS',
    Short: 'KEYS',
  },
  {
    Full: 'KEYS',
    Short: 'KYS',
  },
  {
    Full: 'KEYS',
    Short: 'KYS',
  },
  {
    Full: 'KNOLL',
    Short: 'KNL',
  },
  {
    Full: 'KNOLL',
    Short: 'KNOL',
  },
  {
    Full: 'KNOLL',
    Short: 'KNOLL',
  },
  {
    Full: 'KNOLL',
    Short: 'KNL',
  },
  {
    Full: 'KNOLLS',
    Short: 'KNLS',
  },
  {
    Full: 'KNOLLS',
    Short: 'KNOLLS',
  },
  {
    Full: 'KNOLLS',
    Short: 'KNLS',
  },
  {
    Full: 'LAKE',
    Short: 'LK',
  },
  {
    Full: 'LAKE',
    Short: 'LAKE',
  },
  {
    Full: 'LAKE',
    Short: 'LK',
  },
  {
    Full: 'LAKES',
    Short: 'LKS',
  },
  {
    Full: 'LAKES',
    Short: 'LAKES',
  },
  {
    Full: 'LAKES',
    Short: 'LKS',
  },
  {
    Full: 'LAND',
    Short: 'LAND',
  },
  {
    Full: 'LAND',
    Short: 'LAND',
  },
  {
    Full: 'LANDING',
    Short: 'LANDING',
  },
  {
    Full: 'LANDING',
    Short: 'LNDG',
  },
  {
    Full: 'LANDING',
    Short: 'LNDNG',
  },
  {
    Full: 'LANDING',
    Short: 'LNDG',
  },
  {
    Full: 'LANE',
    Short: 'LANE',
  },
  {
    Full: 'LANE',
    Short: 'LN',
  },
  {
    Full: 'LANE',
    Short: 'LN',
  },
  {
    Full: 'LIGHT',
    Short: 'LGT',
  },
  {
    Full: 'LIGHT',
    Short: 'LIGHT',
  },
  {
    Full: 'LIGHT',
    Short: 'LGT',
  },
  {
    Full: 'LIGHTS',
    Short: 'LIGHTS',
  },
  {
    Full: 'LIGHTS',
    Short: 'LGTS',
  },
  {
    Full: 'LOAF',
    Short: 'LF',
  },
  {
    Full: 'LOAF',
    Short: 'LOAF',
  },
  {
    Full: 'LOAF',
    Short: 'LF',
  },
  {
    Full: 'LOCK',
    Short: 'LCK',
  },
  {
    Full: 'LOCK',
    Short: 'LOCK',
  },
  {
    Full: 'LOCK',
    Short: 'LCK',
  },
  {
    Full: 'LOCKS',
    Short: 'LCKS',
  },
  {
    Full: 'LOCKS',
    Short: 'LOCKS',
  },
  {
    Full: 'LOCKS',
    Short: 'LCKS',
  },
  {
    Full: 'LODGE',
    Short: 'LDG',
  },
  {
    Full: 'LODGE',
    Short: 'LDGE',
  },
  {
    Full: 'LODGE',
    Short: 'LODG',
  },
  {
    Full: 'LODGE',
    Short: 'LODGE',
  },
  {
    Full: 'LODGE',
    Short: 'LDG',
  },
  {
    Full: 'LOOP',
    Short: 'LOOP',
  },
  {
    Full: 'LOOP',
    Short: 'LOOPS',
  },
  {
    Full: 'LOOP',
    Short: 'LOOP',
  },
  {
    Full: 'MALL',
    Short: 'MALL',
  },
  {
    Full: 'MALL',
    Short: 'MALL',
  },
  {
    Full: 'MANOR',
    Short: 'MNR',
  },
  {
    Full: 'MANOR',
    Short: 'MANOR',
  },
  {
    Full: 'MANOR',
    Short: 'MNR',
  },
  {
    Full: 'MANORS',
    Short: 'MANORS',
  },
  {
    Full: 'MANORS',
    Short: 'MNRS',
  },
  {
    Full: 'MANORS',
    Short: 'MNRS',
  },
  {
    Full: 'MEADOW',
    Short: 'MEADOW',
  },
  {
    Full: 'MEADOW',
    Short: 'MDW',
  },
  {
    Full: 'MEADOWS',
    Short: 'MDW',
  },
  {
    Full: 'MEADOWS',
    Short: 'MDWS',
  },
  {
    Full: 'MEADOWS',
    Short: 'MEADOWS',
  },
  {
    Full: 'MEADOWS',
    Short: 'MEDOWS',
  },
  {
    Full: 'MEADOWS',
    Short: 'MDWS',
  },
  {
    Full: 'MEWS',
    Short: 'MEWS',
  },
  {
    Full: 'MEWS',
    Short: 'MEWS',
  },
  {
    Full: 'MILL',
    Short: 'MILL',
  },
  {
    Full: 'MILL',
    Short: 'ML',
  },
  {
    Full: 'MILLS',
    Short: 'MILLS',
  },
  {
    Full: 'MILLS',
    Short: 'MLS',
  },
  {
    Full: 'MISSION',
    Short: 'MISSN',
  },
  {
    Full: 'MISSION',
    Short: 'MSSN',
  },
  {
    Full: 'MISSION',
    Short: 'MSN',
  },
  {
    Full: 'MOTORWAY',
    Short: 'MOTORWAY',
  },
  {
    Full: 'MOTORWAY',
    Short: 'MTWY',
  },
  {
    Full: 'MOUNT',
    Short: 'MNT',
  },
  {
    Full: 'MOUNT',
    Short: 'MT',
  },
  {
    Full: 'MOUNT',
    Short: 'MOUNT',
  },
  {
    Full: 'MOUNT',
    Short: 'MT',
  },
  {
    Full: 'MOUNTAIN',
    Short: 'MNTAIN',
  },
  {
    Full: 'MOUNTAIN',
    Short: 'MNTN',
  },
  {
    Full: 'MOUNTAIN',
    Short: 'MOUNTAIN',
  },
  {
    Full: 'MOUNTAIN',
    Short: 'MOUNTIN',
  },
  {
    Full: 'MOUNTAIN',
    Short: 'MTIN',
  },
  {
    Full: 'MOUNTAIN',
    Short: 'MTN',
  },
  {
    Full: 'MOUNTAIN',
    Short: 'MTN',
  },
  {
    Full: 'MOUNTAINS',
    Short: 'MNTNS',
  },
  {
    Full: 'MOUNTAINS',
    Short: 'MOUNTAINS',
  },
  {
    Full: 'MOUNTAINS',
    Short: 'MTNS',
  },
  {
    Full: 'NECK',
    Short: 'NCK',
  },
  {
    Full: 'NECK',
    Short: 'NECK',
  },
  {
    Full: 'NECK',
    Short: 'NCK',
  },
  {
    Full: 'ORCHARD',
    Short: 'ORCH',
  },
  {
    Full: 'ORCHARD',
    Short: 'ORCHARD',
  },
  {
    Full: 'ORCHARD',
    Short: 'ORCHRD',
  },
  {
    Full: 'ORCHARD',
    Short: 'ORCH',
  },
  {
    Full: 'OVAL',
    Short: 'OVAL',
  },
  {
    Full: 'OVAL',
    Short: 'OVL',
  },
  {
    Full: 'OVAL',
    Short: 'OVAL',
  },
  {
    Full: 'OVERPASS',
    Short: 'OVERPASS',
  },
  {
    Full: 'OVERPASS',
    Short: 'OPAS',
  },
  {
    Full: 'PARK',
    Short: 'PARK',
  },
  {
    Full: 'PARK',
    Short: 'PRK',
  },
  {
    Full: 'PARK',
    Short: 'PARK',
  },
  {
    Full: 'PARKS',
    Short: 'PARKS',
  },
  {
    Full: 'PARKS',
    Short: 'PARK',
  },
  {
    Full: 'PARKWAY',
    Short: 'PARKWAY',
  },
  {
    Full: 'PARKWAY',
    Short: 'PARKWY',
  },
  {
    Full: 'PARKWAY',
    Short: 'PKWAY',
  },
  {
    Full: 'PARKWAY',
    Short: 'PKWY',
  },
  {
    Full: 'PARKWAY',
    Short: 'PKY',
  },
  {
    Full: 'PARKWAY',
    Short: 'PKWY',
  },
  {
    Full: 'PARKWAYS',
    Short: 'PARKWAYS',
  },
  {
    Full: 'PARKWAYS',
    Short: 'PKWYS',
  },
  {
    Full: 'PARKWAYS',
    Short: 'PKWY',
  },
  {
    Full: 'PASS',
    Short: 'PASS',
  },
  {
    Full: 'PASS',
    Short: 'PASS',
  },
  {
    Full: 'PASSAGE',
    Short: 'PASSAGE',
  },
  {
    Full: 'PASSAGE',
    Short: 'PSGE',
  },
  {
    Full: 'PATH',
    Short: 'PATH',
  },
  {
    Full: 'PATH',
    Short: 'PATHS',
  },
  {
    Full: 'PATH',
    Short: 'PATH',
  },
  {
    Full: 'PIKE',
    Short: 'PIKE',
  },
  {
    Full: 'PIKE',
    Short: 'PIKES',
  },
  {
    Full: 'PIKE',
    Short: 'PIKE',
  },
  {
    Full: 'PINE',
    Short: 'PINE',
  },
  {
    Full: 'PINE',
    Short: 'PNE',
  },
  {
    Full: 'PINES',
    Short: 'PINES',
  },
  {
    Full: 'PINES',
    Short: 'PNES',
  },
  {
    Full: 'PINES',
    Short: 'PNES',
  },
  {
    Full: 'PLACE',
    Short: 'PL',
  },
  {
    Full: 'PLACE',
    Short: 'PL',
  },
  {
    Full: 'PLAIN',
    Short: 'PLAIN',
  },
  {
    Full: 'PLAIN',
    Short: 'PLN',
  },
  {
    Full: 'PLAIN',
    Short: 'PLN',
  },
  {
    Full: 'PLAINS',
    Short: 'PLAINS',
  },
  {
    Full: 'PLAINS',
    Short: 'PLNS',
  },
  {
    Full: 'PLAINS',
    Short: 'PLNS',
  },
  {
    Full: 'PLAZA',
    Short: 'PLAZA',
  },
  {
    Full: 'PLAZA',
    Short: 'PLZ',
  },
  {
    Full: 'PLAZA',
    Short: 'PLZA',
  },
  {
    Full: 'PLAZA',
    Short: 'PLZ',
  },
  {
    Full: 'POINT',
    Short: 'POINT',
  },
  {
    Full: 'POINT',
    Short: 'PT',
  },
  {
    Full: 'POINT',
    Short: 'PT',
  },
  {
    Full: 'POINTS',
    Short: 'POINTS',
  },
  {
    Full: 'POINTS',
    Short: 'PTS',
  },
  {
    Full: 'POINTS',
    Short: 'PTS',
  },
  {
    Full: 'PORT',
    Short: 'PORT',
  },
  {
    Full: 'PORT',
    Short: 'PRT',
  },
  {
    Full: 'PORT',
    Short: 'PRT',
  },
  {
    Full: 'PORTS',
    Short: 'PORTS',
  },
  {
    Full: 'PORTS',
    Short: 'PRTS',
  },
  {
    Full: 'PORTS',
    Short: 'PRTS',
  },
  {
    Full: 'PRAIRIE',
    Short: 'PR',
  },
  {
    Full: 'PRAIRIE',
    Short: 'PRAIRIE',
  },
  {
    Full: 'PRAIRIE',
    Short: 'PRR',
  },
  {
    Full: 'PRAIRIE',
    Short: 'PR',
  },
  {
    Full: 'RADIAL',
    Short: 'RAD',
  },
  {
    Full: 'RADIAL',
    Short: 'RADIAL',
  },
  {
    Full: 'RADIAL',
    Short: 'RADIEL',
  },
  {
    Full: 'RADIAL',
    Short: 'RADL',
  },
  {
    Full: 'RADIAL',
    Short: 'RADL',
  },
  {
    Full: 'RAMP',
    Short: 'RAMP',
  },
  {
    Full: 'RAMP',
    Short: 'RAMP',
  },
  {
    Full: 'RANCH',
    Short: 'RANCH',
  },
  {
    Full: 'RANCH',
    Short: 'RANCHES',
  },
  {
    Full: 'RANCH',
    Short: 'RNCH',
  },
  {
    Full: 'RANCH',
    Short: 'RNCHS',
  },
  {
    Full: 'RANCH',
    Short: 'RNCH',
  },
  {
    Full: 'RAPID',
    Short: 'RAPID',
  },
  {
    Full: 'RAPID',
    Short: 'RPD',
  },
  {
    Full: 'RAPID',
    Short: 'RPD',
  },
  {
    Full: 'RAPIDS',
    Short: 'RAPIDS',
  },
  {
    Full: 'RAPIDS',
    Short: 'RPDS',
  },
  {
    Full: 'RAPIDS',
    Short: 'RPDS',
  },
  {
    Full: 'REST',
    Short: 'REST',
  },
  {
    Full: 'REST',
    Short: 'RST',
  },
  {
    Full: 'REST',
    Short: 'RST',
  },
  {
    Full: 'RIDGE',
    Short: 'RDG',
  },
  {
    Full: 'RIDGE',
    Short: 'RDGE',
  },
  {
    Full: 'RIDGE',
    Short: 'RIDGE',
  },
  {
    Full: 'RIDGE',
    Short: 'RDG',
  },
  {
    Full: 'RIDGES',
    Short: 'RDGS',
  },
  {
    Full: 'RIDGES',
    Short: 'RIDGES',
  },
  {
    Full: 'RIDGES',
    Short: 'RDGS',
  },
  {
    Full: 'RIVER',
    Short: 'RIV',
  },
  {
    Full: 'RIVER',
    Short: 'RIVER',
  },
  {
    Full: 'RIVER',
    Short: 'RVR',
  },
  {
    Full: 'RIVER',
    Short: 'RIVR',
  },
  {
    Full: 'RIVER',
    Short: 'RIV',
  },
  {
    Full: 'ROAD',
    Short: 'RD',
  },
  {
    Full: 'ROAD',
    Short: 'ROAD',
  },
  {
    Full: 'ROAD',
    Short: 'RD',
  },
  {
    Full: 'ROADS',
    Short: 'ROADS',
  },
  {
    Full: 'ROADS',
    Short: 'RDS',
  },
  {
    Full: 'ROADS',
    Short: 'RDS',
  },
  {
    Full: 'ROUTE',
    Short: 'ROUTE',
  },
  {
    Full: 'ROUTE',
    Short: 'RTE',
  },
  {
    Full: 'ROW',
    Short: 'ROW',
  },
  {
    Full: 'ROW',
    Short: 'ROW',
  },
  {
    Full: 'RUE',
    Short: 'RUE',
  },
  {
    Full: 'RUE',
    Short: 'RUE',
  },
  {
    Full: 'RUN',
    Short: 'RUN',
  },
  {
    Full: 'RUN',
    Short: 'RUN',
  },
  {
    Full: 'SHOAL',
    Short: 'SHL',
  },
  {
    Full: 'SHOAL',
    Short: 'SHOAL',
  },
  {
    Full: 'SHOAL',
    Short: 'SHL',
  },
  {
    Full: 'SHOALS',
    Short: 'SHLS',
  },
  {
    Full: 'SHOALS',
    Short: 'SHOALS',
  },
  {
    Full: 'SHOALS',
    Short: 'SHLS',
  },
  {
    Full: 'SHORE',
    Short: 'SHOAR',
  },
  {
    Full: 'SHORE',
    Short: 'SHORE',
  },
  {
    Full: 'SHORE',
    Short: 'SHR',
  },
  {
    Full: 'SHORE',
    Short: 'SHR',
  },
  {
    Full: 'SHORES',
    Short: 'SHOARS',
  },
  {
    Full: 'SHORES',
    Short: 'SHORES',
  },
  {
    Full: 'SHORES',
    Short: 'SHRS',
  },
  {
    Full: 'SHORES',
    Short: 'SHRS',
  },
  {
    Full: 'SKYWAY',
    Short: 'SKYWAY',
  },
  {
    Full: 'SKYWAY',
    Short: 'SKWY',
  },
  {
    Full: 'SPRING',
    Short: 'SPG',
  },
  {
    Full: 'SPRING',
    Short: 'SPNG',
  },
  {
    Full: 'SPRING',
    Short: 'SPRING',
  },
  {
    Full: 'SPRING',
    Short: 'SPRNG',
  },
  {
    Full: 'SPRING',
    Short: 'SPG',
  },
  {
    Full: 'SPRINGS',
    Short: 'SPGS',
  },
  {
    Full: 'SPRINGS',
    Short: 'SPNGS',
  },
  {
    Full: 'SPRINGS',
    Short: 'SPRINGS',
  },
  {
    Full: 'SPRINGS',
    Short: 'SPRNGS',
  },
  {
    Full: 'SPRINGS',
    Short: 'SPGS',
  },
  {
    Full: 'SPUR',
    Short: 'SPUR',
  },
  {
    Full: 'SPUR',
    Short: 'SPUR',
  },
  {
    Full: 'SPURS',
    Short: 'SPURS',
  },
  {
    Full: 'SPURS',
    Short: 'SPUR',
  },
  {
    Full: 'SQUARE',
    Short: 'SQ',
  },
  {
    Full: 'SQUARE',
    Short: 'SQR',
  },
  {
    Full: 'SQUARE',
    Short: 'SQRE',
  },
  {
    Full: 'SQUARE',
    Short: 'SQU',
  },
  {
    Full: 'SQUARE',
    Short: 'SQUARE',
  },
  {
    Full: 'SQUARE',
    Short: 'SQ',
  },
  {
    Full: 'SQUARES',
    Short: 'SQRS',
  },
  {
    Full: 'SQUARES',
    Short: 'SQUARES',
  },
  {
    Full: 'SQUARES',
    Short: 'SQS',
  },
  {
    Full: 'STATION',
    Short: 'STA',
  },
  {
    Full: 'STATION',
    Short: 'STATION',
  },
  {
    Full: 'STATION',
    Short: 'STATN',
  },
  {
    Full: 'STATION',
    Short: 'STN',
  },
  {
    Full: 'STATION',
    Short: 'STA',
  },
  {
    Full: 'STRAVENUE',
    Short: 'STRA',
  },
  {
    Full: 'STRAVENUE',
    Short: 'STRAV',
  },
  {
    Full: 'STRAVENUE',
    Short: 'STRAVEN',
  },
  {
    Full: 'STRAVENUE',
    Short: 'STRAVENUE',
  },
  {
    Full: 'STRAVENUE',
    Short: 'STRAVN',
  },
  {
    Full: 'STRAVENUE',
    Short: 'STRVN',
  },
  {
    Full: 'STRAVENUE',
    Short: 'STRVNUE',
  },
  {
    Full: 'STRAVENUE',
    Short: 'STRA',
  },
  {
    Full: 'STREAM',
    Short: 'STREAM',
  },
  {
    Full: 'STREAM',
    Short: 'STREME',
  },
  {
    Full: 'STREAM',
    Short: 'STRM',
  },
  {
    Full: 'STREAM',
    Short: 'STRM',
  },
  {
    Full: 'STREET',
    Short: 'STREET',
  },
  {
    Full: 'STREET',
    Short: 'STRT',
  },
  {
    Full: 'STREET',
    Short: 'ST',
  },
  {
    Full: 'STREET',
    Short: 'STR',
  },
  {
    Full: 'STREET',
    Short: 'ST',
  },
  {
    Full: 'STREETS',
    Short: 'STREETS',
  },
  {
    Full: 'STREETS',
    Short: 'STS',
  },
  {
    Full: 'SUMMIT',
    Short: 'SMT',
  },
  {
    Full: 'SUMMIT',
    Short: 'SUMIT',
  },
  {
    Full: 'SUMMIT',
    Short: 'SUMITT',
  },
  {
    Full: 'SUMMIT',
    Short: 'SUMMIT',
  },
  {
    Full: 'SUMMIT',
    Short: 'SMT',
  },
  {
    Full: 'TERRACE',
    Short: 'TER',
  },
  {
    Full: 'TERRACE',
    Short: 'TERR',
  },
  {
    Full: 'TERRACE',
    Short: 'TERRACE',
  },
  {
    Full: 'TERRACE',
    Short: 'TER',
  },
  {
    Full: 'THROUGHWAY',
    Short: 'THROUGHWAY',
  },
  {
    Full: 'THROUGHWAY',
    Short: 'TRWY',
  },
  {
    Full: 'TRACE',
    Short: 'TRACE',
  },
  {
    Full: 'TRACE',
    Short: 'TRACES',
  },
  {
    Full: 'TRACE',
    Short: 'TRCE',
  },
  {
    Full: 'TRACE',
    Short: 'TRCE',
  },
  {
    Full: 'TRACK',
    Short: 'TRACK',
  },
  {
    Full: 'TRACK',
    Short: 'TRACKS',
  },
  {
    Full: 'TRACK',
    Short: 'TRAK',
  },
  {
    Full: 'TRACK',
    Short: 'TRK',
  },
  {
    Full: 'TRACK',
    Short: 'TRKS',
  },
  {
    Full: 'TRACK',
    Short: 'TRAK',
  },
  {
    Full: 'TRAFFICWAY',
    Short: 'TRAFFICWAY',
  },
  {
    Full: 'TRAFFICWAY',
    Short: 'TRFY',
  },
  {
    Full: 'TRAIL',
    Short: 'TRAIL',
  },
  {
    Full: 'TRAIL',
    Short: 'TRAILS',
  },
  {
    Full: 'TRAIL',
    Short: 'TRL',
  },
  {
    Full: 'TRAIL',
    Short: 'TRLS',
  },
  {
    Full: 'TRAIL',
    Short: 'TRL',
  },
  {
    Full: 'TRAILER',
    Short: 'TRAILER',
  },
  {
    Full: 'TRAILER',
    Short: 'TRLR',
  },
  {
    Full: 'TRAILER',
    Short: 'TRLRS',
  },
  {
    Full: 'TRAILER',
    Short: 'TRLR',
  },
  {
    Full: 'TUNNEL',
    Short: 'TUNEL',
  },
  {
    Full: 'TUNNEL',
    Short: 'TUNL',
  },
  {
    Full: 'TUNNEL',
    Short: 'TUNLS',
  },
  {
    Full: 'TUNNEL',
    Short: 'TUNNEL',
  },
  {
    Full: 'TUNNEL',
    Short: 'TUNNELS',
  },
  {
    Full: 'TUNNEL',
    Short: 'TUNNL',
  },
  {
    Full: 'TUNNEL',
    Short: 'TUNL',
  },
  {
    Full: 'TURNPIKE',
    Short: 'TRNPK',
  },
  {
    Full: 'TURNPIKE',
    Short: 'TURNPIKE',
  },
  {
    Full: 'TURNPIKE',
    Short: 'TURNPK',
  },
  {
    Full: 'TURNPIKE',
    Short: 'TPKE',
  },
  {
    Full: 'UNDERPASS',
    Short: 'UNDERPASS',
  },
  {
    Full: 'UNDERPASS',
    Short: 'UPAS',
  },
  {
    Full: 'UNION',
    Short: 'UN',
  },
  {
    Full: 'UNION',
    Short: 'UNION',
  },
  {
    Full: 'UNION',
    Short: 'UN',
  },
  {
    Full: 'UNIONS',
    Short: 'UNIONS',
  },
  {
    Full: 'UNIONS',
    Short: 'UNS',
  },
  {
    Full: 'VALLEY',
    Short: 'VALLEY',
  },
  {
    Full: 'VALLEY',
    Short: 'VALLY',
  },
  {
    Full: 'VALLEY',
    Short: 'VLLY',
  },
  {
    Full: 'VALLEY',
    Short: 'VLY',
  },
  {
    Full: 'VALLEY',
    Short: 'VLY',
  },
  {
    Full: 'VALLEYS',
    Short: 'VALLEYS',
  },
  {
    Full: 'VALLEYS',
    Short: 'VLYS',
  },
  {
    Full: 'VALLEYS',
    Short: 'VLYS',
  },
  {
    Full: 'VIADUCT',
    Short: 'VDCT',
  },
  {
    Full: 'VIADUCT',
    Short: 'VIA',
  },
  {
    Full: 'VIADUCT',
    Short: 'VIADCT',
  },
  {
    Full: 'VIADUCT',
    Short: 'VIADUCT',
  },
  {
    Full: 'VIADUCT',
    Short: 'VIA',
  },
  {
    Full: 'VIEW',
    Short: 'VIEW',
  },
  {
    Full: 'VIEW',
    Short: 'VW',
  },
  {
    Full: 'VIEW',
    Short: 'VW',
  },
  {
    Full: 'VIEWS',
    Short: 'VIEWS',
  },
  {
    Full: 'VIEWS',
    Short: 'VWS',
  },
  {
    Full: 'VIEWS',
    Short: 'VWS',
  },
  {
    Full: 'VILLAGE',
    Short: 'VILL',
  },
  {
    Full: 'VILLAGE',
    Short: 'VILLAG',
  },
  {
    Full: 'VILLAGE',
    Short: 'VILLAGE',
  },
  {
    Full: 'VILLAGE',
    Short: 'VILLG',
  },
  {
    Full: 'VILLAGE',
    Short: 'VILLIAGE',
  },
  {
    Full: 'VILLAGE',
    Short: 'VLG',
  },
  {
    Full: 'VILLAGE',
    Short: 'VLG',
  },
  {
    Full: 'VILLAGES',
    Short: 'VILLAGES',
  },
  {
    Full: 'VILLAGES',
    Short: 'VLGS',
  },
  {
    Full: 'VILLAGES',
    Short: 'VLGS',
  },
  {
    Full: 'VILLE',
    Short: 'VILLE',
  },
  {
    Full: 'VILLE',
    Short: 'VL',
  },
  {
    Full: 'VILLE',
    Short: 'VL',
  },
  {
    Full: 'VISTA',
    Short: 'VIS',
  },
  {
    Full: 'VISTA',
    Short: 'VIST',
  },
  {
    Full: 'VISTA',
    Short: 'VISTA',
  },
  {
    Full: 'VISTA',
    Short: 'VST',
  },
  {
    Full: 'VISTA',
    Short: 'VSTA',
  },
  {
    Full: 'VISTA',
    Short: 'VIS',
  },
  {
    Full: 'WALK',
    Short: 'WALK',
  },
  {
    Full: 'WALK',
    Short: 'WALK',
  },
  {
    Full: 'WALKS',
    Short: 'WALKS',
  },
  {
    Full: 'WALKS',
    Short: 'WALK',
  },
  {
    Full: 'WALL',
    Short: 'WALL',
  },
  {
    Full: 'WALL',
    Short: 'WALL',
  },
  {
    Full: 'WAY',
    Short: 'WY',
  },
  {
    Full: 'WAY',
    Short: 'WAY',
  },
  {
    Full: 'WAY',
    Short: 'WAY',
  },
  {
    Full: 'WAYS',
    Short: 'WAYS',
  },
  {
    Full: 'WAYS',
    Short: 'WAYS',
  },
  {
    Full: 'WELL',
    Short: 'WELL',
  },
  {
    Full: 'WELL',
    Short: 'WL',
  },
  {
    Full: 'WELLS',
    Short: 'WELLS',
  },
  {
    Full: 'WELLS',
    Short: 'WLS',
  },
  {
    Full: 'WELLS',
    Short: 'WLS',
  },
];
