export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_SBL = 'SET_SBL';

export const setParsedAddress = (input) => ({
  type: SET_ADDRESS,
  payload: input,
});

export const setSBL = (input) => ({
  type: SET_SBL,
  payload: input,
});

const initAddress = {
  county: '',
  houseNo: '',
  street: '',
  fullAddress: '',
  boro: null,
  block: null,
  lot: null,
  easement: null,
};

export const addressReducer = (state = initAddress, action) => {
  switch (action.type) {
    case SET_ADDRESS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_SBL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
