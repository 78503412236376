/* eslint-disable import/prefer-default-export */
export const boros = [
  'Brooklyn',
  'Manhattan',
  'Queens',
  'Staten Island',
  'The Bronx',
];

export const SMARTYST_API = '104583779917133701';

export const GRAPHQL_SERVER_URL = process.env.REACT_APP_GRAPHQL_SERVER_URL || 'http://localhost:4000/graphql';
