export const SET_INTRO = 'SET_INTRO';

export const setIntro = (input) => ({
  type: SET_INTRO,
  payload: input,
});

const initIntro = localStorage.getItem('intro');

export const introReducer = (state = initIntro, action) => {
  switch (action.type) {
    case SET_INTRO:
      localStorage.setItem('intro', action.payload);
      return action.payload;
    default:
      return state;
  }
};
